<template>
  <nav
    class=" top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow">
    <div class="container px-4 mx-auto flex flex-wrap items-center justify-between">
      <div class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
        <router-link to="/">
          <div class="mr-4 navbar-brand">
            <!-- <img src="../assets/img/logo/logo-white.png" class="white-image w-20 mt-4" alt="safex logo"> -->
            <img src="../assets/img/logo/logo-main.png" class="main-imasge w-1/2" alt="safex logo">
          </div>
        </router-link>
        <button
          class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
          type="button" v-on:click="setNavbarOpen">
          <i class="fas fa-bars"></i>
        </button>
      </div>
      <div class="lg:flex flex-grow items-center" :class="[navbarOpen ? 'block' : 'hidden']" id="example-navbar-warning">

        <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">
          <!-- <li class="flex items-center">
            <index-dropdown />
          </li> -->
          <li class=" flex items-center nav-item active">
            <a class="hover:text-gray-500 text-gray-700 px-3 py-2 flex items-center text-xs uppercase font-semibold page-scroll "
              href="#home">Home</a>
          </li>
          <li class=" flex items-center nav-item active">
            <a class="hover:text-gray-500 text-gray-700 px-3 py-2 flex items-center text-xs uppercase font-semibold page-scroll "
              href="#offering">Offers</a>
          </li>
          <li class=" flex items-center nav-item active">
            <a class="hover:text-gray-500 text-gray-700 px-3 py-2 flex items-center text-xs uppercase font-semibold page-scroll "
              href="#heroes">Heroes</a>
          </li>
          <li class=" flex items-center nav-item active">
            <a target="_blank" href="https://app.safex.africa/our-rates" class="hover:text-gray-500 text-gray-700 px-3 py-2 flex items-center text-xs uppercase font-semibold page-scroll "
              >Rate Calculator</a>
          </li>


          <li class="flex items-center">
            <a class="text-blue-500 px-3 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
              href="https://app.safex.africa/login" target="_blank">
              <i class="fas fa-sign-in-alt"></i>
              Login</a>
            <a class="text-white bg-blue-500 px-3 py-2 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
              href="https://app.safex.africa/register" target="_blank">
              <i class="fas fa-user-plus"></i>
              Sign Up
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
// import IndexDropdown from "@/components/Dropdowns/IndexDropdown.vue";

export default {
  data() {
    return {
      navbarOpen: false,
    };
  },
  methods: {
    setNavbarOpen: function () {
      this.navbarOpen = !this.navbarOpen;
    },
  },
  // components: {
  //   IndexDropdown,
  // },
};
</script>
