<template>
    <section id="about" class="relative pt-20 about-area z-30">
        <div class="container">
            <div class="row">
                <div class="w-full lg:w-1/2 order-last">
                    <div class="mx-4 mt-12 about-content" data-aos="fade-left" data-aos-duration="2000" data-aos-easing="ease-in-out">
                        <div class="mb-4 section-title">
                            <div class="line"></div>
                            <h3 class="title">Meet the SafeX Team.</h3>
                        </div>
                        <p class="mb-8">We are a team of young champs with years of experience in finance, research and digital transformation. Leveraging decentralised technology to drive digital and economic inclusion in Africa. We are anxious about what the future world of finance and trades holds, that is why we have created a platform for the future. We focus on ensuring excellence in all our engagement with stakeholders (clients, partners, regulators, colleagues and the public), bringing to the world a simplified and safe experience.</p>
                        <!-- <a href="javascript:void(0)" class="main-btn ">Join the tribe</a> -->
                    </div> 
                </div>
                <div class="w-full lg:w-1/2 order-first">
                    <div class="mx-4 mt-12 text-center about-image" data-aos="fade-right" data-aos-duration="2000" data-aos-easing="ease-in-out">
                        <img src="../assets/img/team.png" class="hover:rotate-180 transition duration-1000" alt="about">
                    </div>
                </div>
            </div> 
        </div> 
        <div class="about-shape-1">
            <img src="../assets/img/about-shape-1.svg" alt="shape">
        </div>
    </section>
</template>