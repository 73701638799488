<template>
    <div id="home" class="header-hero">
        <div>
            <div class="flex pt-48 px-20 lg:pt-10 lg:pb-40 justify-center flex-wrap">
                <div class="lg:w-1/2 ">
                    <div class="text-center lg:pt-48 mb-24 header-hero-content" >
                        <h2 class="mb-10 text-5xl font-semibold text-left text-white header-title">Sell your Gift Cards
                            for Naira in Minutes.</h2>
                        <p class="mb-5 text-white text-lg leading-relaxed text-left">Thousands of Users trust us to
                            redeem their Gift cards for amazing rates in Minutes.
                            Join the SafeX tribe.</p>
                        <ul class="flex justify-start gap-3">
                            <li><a href="https://apps.apple.com/app/safex-africa/id1639957931" target="_blank"><img
                                        class="rounded-md hover:scale-105 transition duration-700"
                                        src="../assets/icons/app-store-badge.png" alt="appstore"></a></li>
                            <li><a href="https://play.google.com/store/apps/details?id=com.safex.safex"
                                    target="_blank"><img class="rounded-md hover:scale-105 transition duration-700"
                                        src="../assets/icons/google-play-badge.png" alt="playstore"></a></li>
                        </ul>
                    </div>
                </div>
                <div class="lg:w-1/2 flex  justify-center lg:justify-end eader-hero-image" >
                    <img src="../assets/img/phones_.webp" class="mt-32" alt="hero">
                </div>
            </div>
        </div>

        <!-- <div id="particles-1" class="z-10 particles">
            <ParticlesConfig />
        </div> -->
    </div>

</template>
<!-- <script setup>
import ParticlesConfig from "./ParticlesConfig.vue";
</script> -->