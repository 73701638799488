<template>
    <section id="values" class="services-area pt-120 z-30">
        <div class="container" data-aos="fade-down">
            <div class="justify-center row">
                <div class="w-full lg:w-2/3">
                    <div class="pb-10 text-center section-title">
                        <div class="m-auto line"></div>
                        <h3 class="title">Our Value Propositions</h3>
                    </div>
                </div>
            </div>
            
            <div class="flex flex-wrap justify-center row">
                <div class="w-fit md:w-1/2">
                    <div class="single-services" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out">
                        <div class="services-icon">
                            <img class="shape w-20" src="../assets/icons/shield.webp " alt="Trust Icon">
                        </div>
                        <div class="mt-8 services-content">
                            <h4 class="mb-2 text-xl font-bold text-gray-900">Trust</h4>
                            <p class="mb-8">We ensure a fast, secure, and seamless transaction.</p>
                        </div>
                    </div>
                </div>
                <div class="w-fit md:w-1/2">
                    <div class="mt-8 text-center single-services" data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out">
                        <div class="services-icon">
                            <img class="shape w-20" src="../assets/icons/availability.svg" alt="convenience icon">
                        </div>
                        <div class="mt-8 services-content">
                            <h4 class="mb-2 text-xl font-bold text-gray-900">Instant payment</h4>
                            <p class="mb-8">We guarantee payments faster than the speed of light..</p>
                        </div>
                    </div>
                </div>
                <div class="w-fit md:w-1/2">
                    <div class="mt-8 text-center single-services" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out">
                        <div class="services-icon">
                            <img class="shape w-20" src="../assets/icons/global.webp" alt="Value icon">
                        </div>
                        <div class="mt-8 services-content">
                            <h4 class="mb-2 text-xl font-bold text-gray-900">Availability</h4>
                            <p class="mb-8">We are available across all platforms. You can complete your transactions
                                anytime and anywhere.</p>
                        </div>
                    </div>
                </div>
                <div class="w-fit md:w-1/2">
                    <div class="mt-8 text-center single-services" data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out">
                        <div class="services-icon">
                            <img class="shape w-20" src="../assets/icons/support.svg" alt="excellence icon">
                        </div>
                        <div class="mt-8 services-content">
                            <h4 class="mb-2 text-xl font-bold text-gray-900">Real time Support</h4>
                            <p class="mb-8">We are available to provide answers to your questions and give you excellent
                                support 24/7 and in real-time.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>