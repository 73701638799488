<template>
    <section id="offering" class="relative pt-20 about-area z-30">
        <div class="about-shape-2">
            <img src="../assets/img/about-shape-2.svg" alt="shape">
        </div>
        <div class="container my-20">
            <div class="row">
                <div class="w-full lg:w-1/2 order-last">
                    <div class="grid grid-cols-3 gap-5" data-aos="fade-left" data-aos-duration="2000" data-aos-easing="ease-in-out">
                        <img src="../assets/icons/itunes.webp" alt="itunes" class="w-20 cursor-pointer hover:scale-110 hover:rotate-45 transition duration-700" height="100" width="100">
                        <img src="../assets/icons/american-express.webp" alt="american-express" class="w-20 cursor-pointer hover:rotate-45 hover:scale-110 transition duration-700" height="100" width="100">                        
                        <img src="../assets/icons/amazon.webp" alt="amazon" class="w-20 cursor-pointer hover:rotate-45 hover:scale-110 transition duration-700" height="100" width="100">                        
                        <img src="../assets/icons/app-store.webp" alt="app-store" class="w-20 cursor-pointer hover:rotate-45 hover:scale-110 transition duration-700" height="100" width="100">                        
                        <img src="../assets/icons/best-buy.webp" alt="best-buy" class="w-20 cursor-pointer hover:rotate-45 hover:scale-110 transition duration-700" height="100" width="100">                        
                        <img src="../assets/icons/ebay.webp" alt="ebay" class="w-20 cursor-pointer hover:rotate-45 hover:scale-110 transition duration-700" height="100" width="100">                        
                        <img src="../assets/icons/steam.webp" alt="steam" class="w-20 cursor-pointer hover:rotate-45 hover:scale-110 transition duration-700" height="100" width="100">                        
                        <img src="../assets/icons/play-store.webp" alt="play-store" class="w-20 cursor-pointer hover:rotate-45 hover:scale-110 transition duration-700" height="100" width="100">                        
                        <img src="../assets/icons/sephora.webp" alt="sephora" class="w-28 cursor-pointer hover:rotate-45 hover:scale-110 transition duration-700" height="150" width="150">      
                    </div> 
                </div>
                <div class="w-full lg:w-1/2 order-first">
                    <div class="mx-4 mt-12 mb-10 about-content" data-aos="fade-right" data-aos-duration="2000" data-aos-easing="ease-in-out">
                        <div class="mb-4 section-title">
                            <div class="line"></div>
                            <h3 class="title">What we offer</h3>
                                <p>At SafeX Africa, we accept several gift cards from different countries. We offer amazing rates and swift payments for the variety of gift cards.</p>
                        </div> 
                        <a href="https://linktr.ee/safexafrica" target="__blank" class="main-btn">Download</a>
                    </div> 
                </div>
            </div> 
        </div> 
    </section>
</template>