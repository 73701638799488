// import { createApp } from 'vue'
// import App from './App.vue'
// import "@/assets/styles/index.css";

// createApp(App).mount('#app')

import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";

// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/index.css";

// import 'animate.css';
// import AOS from "aos";
// import 'aos/dist/aos.css'
// AOS.init();

import App from "@/App.vue";


import HomeView from "@/views/HomeView.vue";


// routes

const routes = [
  {
    path: "/",
    component: HomeView,
    meta: { title: "Sell your giftcards to a trusted partner | SafeX" },
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(import.meta.BASE_URL),
  routes,
});

// createApp(App).mount("#app");
createApp(App)
// .component('font-awesome-icon', FontAwesomeIcon)
.use(router)
.mount('#app')

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});
export default router;