<template>
    <section id="heroes" class="team-area pt-120 z-30">
        <div class="container">
            <div class="justify-center row">
                <div class="w-full lg:w-2/3">
                    <div class="pb-8 text-center section-title">
                        <div class="m-auto line"></div>
                        <h3 class="title"><span>SafeX</span> Heroes</h3>
                        <span>
                            Asides the amazing rates that we offer, we also recognize and appreciate our SafeX Heroes through our end of the month bonuses and several promos.
                        </span>
                    </div> 
                </div>
            </div> 
            <div class="justify-center row">
                <div class="w-full sm:w-2/3 lg:w-1/3">
                    <div class="mt-8 text-center single-team" data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out">
                        <div class="flex justify-center team-image">
                            <img class="w-full" src="../assets/icons/captain.webp" alt="Team">
                        </div>
                        <div class="p-8">
                            <h5 class="mb-1 text-xl font-bold text-gray-900">SafeX Captain</h5>
                            <p>Win a cash bonus of 50,000 Naira and stand a chance to become the face of SafeX for a month when you complete up to $20,000 worth of Gift cards transaction in a month..</p>
                        </div>
                    </div> 
                </div>
                <div class="w-full sm:w-2/3 lg:w-1/3">
                    <div class="mt-8 text-center single-team" data-aos="fade-up" data-aos-duration="2000" data-aos-easing="ease-in-out">
                        <div class="relative team-image">
                            <img class="w-full" src="../assets/icons/marshal.svg" alt="Team">
                        </div>
                        <div class="p-8">
                            <h5 class="mb-1 text-xl font-bold text-gray-900">SafeX Marshal</h5>
                            <p>Win a cash bonus of 5,000 Naira when you complete up to $1,000 worth of Gift cards transaction in a month.</p>
                        </div>
                    </div>
                </div>
                <div class="w-full sm:w-2/3 lg:w-1/3">
                    <div class="mt-8 text-center single-team" data-aos="fade-up" data-aos-duration="2500" data-aos-easing="ease-in-out">
                        <div class="relative team-image">
                            <img class="w-full" src="../assets/icons/master.svg" alt="Team">
                        </div>
                        <div class="p-8">
                            <h5 class="mb-1 text-xl font-bold text-gray-900">SafeX Master</h5>
                            <p>Gifts Cards trade upto $1000 in one month - 10 people to win 5,000 cash prize each.</p>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </section>
</template>